import { useContext, useEffect, useState } from 'react';
import { type ProductSearchInput, type ProductViewEnum } from '../../__generated__/graphql-client-types';
import { DEFAULT_PAGESIZE } from '../../constants/search';
import { ImpressionsContext } from '../../contexts/impressions/impressions.context';
import { type TrackedEvent, TrackedEventCase } from '../../helpers/analytics/event-types';
import { buildGTMViewSearchResults } from '../../helpers/analytics/gtm/event-builders';
import { buildGTMSearchImpressions, trackGTMEvent } from '../../helpers/analytics/gtm/gtm-utils.helper';
import type { ProductImpression, ProductSearchResult } from '../../types/search.types';
import { doesWindowExist } from '../../utils/dom/dom.utils';

function fireImpressionsTracking(
	eventType: TrackedEvent,
	impressionListName: string,
	impressions: ProductImpression[],
	request: ProductSearchInput,
	results?: ProductSearchResult,
	viewType?: ProductViewEnum,
	nonstock = false
) {
	if (!doesWindowExist()) {
		return;
	}
	const gtmSearchImpressions = buildGTMSearchImpressions(impressions, impressionListName);
	const gtmViewSearchResults = buildGTMViewSearchResults(
		eventType,
		TrackedEventCase.SEARCH_RESULTS_LOAD,
		gtmSearchImpressions,
		request,
		results,
		viewType,
		nonstock
	);
	trackGTMEvent(gtmViewSearchResults);
}

/**
 * Hook to fire impressions analytics events. Collects impressions to send as a batch to avoid
 * reaching GA's maximum hits per session of 500.
 *
 * @param eventType
 * @param impressionListName
 * @param searchRequest
 * @param searchResults
 * @param viewType
 * @param nonstock
 * @see https://www.simoahava.com/analytics/true-view-impressions-google-tag-manager/
 */
export function useTrackImpressions(
	eventType: TrackedEvent,
	impressionListName: string,
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType: ProductViewEnum = 'TILE',
	nonstock = false
) {
	const { impressions, removeImpression } = useContext(ImpressionsContext);
	const [previousSearch, setPreviousSearch] = useState({ searchRequest, searchResults });
	const { searchRequest: previousSearchRequest, searchResults: previousSearchResults } = previousSearch;

	// If search request changes, fire any outstanding impressions and clear the queue.
	useEffect(() => {
		const requestChanged = searchRequest !== previousSearchRequest;
		const resultsChanged = searchResults !== previousSearchResults;

		if (previousSearchRequest && requestChanged && impressions.length) {
			fireImpressionsTracking(
				eventType,
				impressionListName,
				impressions,
				previousSearchRequest,
				previousSearchResults,
				viewType,
				nonstock
			);
			removeImpression(impressions);
		}

		// After firing old impressions, update previous search to the current search.
		if (requestChanged || resultsChanged) {
			setPreviousSearch({
				searchRequest: requestChanged ? searchRequest : previousSearchRequest,
				searchResults: resultsChanged ? searchResults : previousSearchResults
			});
		}
	}, [
		previousSearchRequest,
		previousSearchResults,
		searchRequest,
		searchResults,
		eventType,
		impressions,
		removeImpression,
		viewType,
		nonstock,
		impressionListName
	]);

	useEffect(() => {
		const fireImpressions = () => {
			if (impressions.length) {
				fireImpressionsTracking(eventType, impressionListName, impressions, searchRequest, searchResults, viewType, nonstock);
				removeImpression(impressions);
			}
		};

		if (impressions.length >= DEFAULT_PAGESIZE) {
			fireImpressions();
		}

		// Fire a tracking event if there were zero results for the search.
		if (searchResults?.count === 0) {
			fireImpressionsTracking(eventType, impressionListName, [], searchRequest, searchResults, viewType, nonstock);
		}

		// Send accumulated impressions immediately if interval has passed to avoid lost impressions if user navigates away.
		const impressionsQueueTimerInterval = 3 * 1000;
		const impressionsQueueTimer = setInterval(fireImpressions, impressionsQueueTimerInterval);
		return () => clearInterval(impressionsQueueTimer);
	}, [searchRequest, searchResults, eventType, impressions, removeImpression, viewType, nonstock, impressionListName]);
}
